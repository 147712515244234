var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_vm.skip3rdStep ? _c('div', {
    staticClass: "content"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": true
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm.viewType === 'newInvestor' ? _c('span', [_vm._v("Add new investor")]) : _vm._e(), _vm.viewType === 'editInvestor' ? _c('span', [_vm._v("Edit investor")]) : _vm._e(), _vm.viewType === 'verifyInvestor' ? _c('span', [_vm._v("Authorize your data")]) : _vm._e()]), _c('div', {
    staticClass: "content__title "
  }, [_vm._v(" Residential address ")]), _vm.displayField('residential_address_street') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'STREET',
      "validation": _vm.$v.investmentData.residentialAddress.street
    },
    model: {
      value: _vm.investmentData.residentialAddress.street,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "street", $$v);
      },
      expression: "investmentData.residentialAddress.street"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.displayField('residential_address_house_number') ? _c('sygni-input', {
    class: ['secondary', _vm.displayField('residential_address_flat_number') ? 'mr-4' : ''],
    attrs: {
      "label": 'HOUSE NO.',
      "validation": _vm.$v.investmentData.residentialAddress.houseNumber
    },
    model: {
      value: _vm.investmentData.residentialAddress.houseNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "houseNumber", $$v);
      },
      expression: "investmentData.residentialAddress.houseNumber"
    }
  }) : _vm._e(), _vm.displayField('residential_address_flat_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FLAT NO.',
      "validation": _vm.$v.investmentData.residentialAddress.flatNumber
    },
    model: {
      value: _vm.investmentData.residentialAddress.flatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "flatNumber", $$v);
      },
      expression: "investmentData.residentialAddress.flatNumber"
    }
  }) : _vm._e()], 1), _vm.displayField('residential_address_city') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'CITY',
      "validation": _vm.$v.investmentData.residentialAddress.city
    },
    model: {
      value: _vm.investmentData.residentialAddress.city,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "city", $$v);
      },
      expression: "investmentData.residentialAddress.city"
    }
  }) : _vm._e(), _c('div', [_vm.displayField('residential_address_zip_code') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'ZIP CODE',
      "validation": _vm.$v.investmentData.residentialAddress.zipCode
    },
    model: {
      value: _vm.investmentData.residentialAddress.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "zipCode", $$v);
      },
      expression: "investmentData.residentialAddress.zipCode"
    }
  }) : _vm._e()], 1), _vm.displayField('residential_address_country') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'COUNTRY',
      "searchable": true,
      "placeholder": 'Choose',
      "validation": _vm.$v.investmentData.mailingAddress.country
    },
    model: {
      value: _vm.investmentData.residentialAddress.country,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "country", $$v);
      },
      expression: "investmentData.residentialAddress.country"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Mailing address ")]), _c('sygni-checkbox', {
    staticClass: "secondary ml-auto same-as-residential-button",
    attrs: {
      "label": 'SAME AS RESIDENTIAL'
    },
    model: {
      value: _vm.investmentData.isMailingAddressSameResidential,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "isMailingAddressSameResidential", $$v);
      },
      expression: "investmentData.isMailingAddressSameResidential"
    }
  })], 1), !_vm.investmentData.isMailingAddressSameResidential ? [_vm.displayField('mailing_address_street') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'STREET',
      "validation": _vm.$v.investmentData.mailingAddress.street
    },
    model: {
      value: _vm.investmentData.mailingAddress.street,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "street", $$v);
      },
      expression: "investmentData.mailingAddress.street"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.displayField('mailing_address_house_number') ? _c('sygni-input', {
    class: ['secondary', _vm.displayField('mailing_address_flat_number') ? 'mr-4' : ''],
    attrs: {
      "label": 'HOUSE NO.',
      "validation": _vm.$v.investmentData.mailingAddress.houseNumber
    },
    model: {
      value: _vm.investmentData.mailingAddress.houseNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "houseNumber", $$v);
      },
      expression: "investmentData.mailingAddress.houseNumber"
    }
  }) : _vm._e(), _vm.displayField('mailing_address_flat_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FLAT NO.',
      "validation": _vm.$v.investmentData.mailingAddress.flatNumber
    },
    model: {
      value: _vm.investmentData.mailingAddress.flatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "flatNumber", $$v);
      },
      expression: "investmentData.mailingAddress.flatNumber"
    }
  }) : _vm._e()], 1), _vm.displayField('mailing_address_city') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'CITY',
      "validation": _vm.$v.investmentData.mailingAddress.city
    },
    model: {
      value: _vm.investmentData.mailingAddress.city,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "city", $$v);
      },
      expression: "investmentData.mailingAddress.city"
    }
  }) : _vm._e(), _c('div', [_vm.displayField('mailing_address_zip_code') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'ZIP CODE',
      "validation": _vm.$v.investmentData.mailingAddress.zipCode
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.$touch();
      }
    },
    model: {
      value: _vm.investmentData.mailingAddress.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "zipCode", $$v);
      },
      expression: "investmentData.mailingAddress.zipCode"
    }
  }) : _vm._e()], 1), _vm.displayField('mailing_address_country') ? _c('sygni-select', {
    staticClass: "secondary mb-3",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'COUNTRY',
      "searchable": true,
      "placeholder": 'Choose',
      "validation": _vm.$v.investmentData.mailingAddress.country
    },
    model: {
      value: _vm.investmentData.mailingAddress.country,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "country", $$v);
      },
      expression: "investmentData.mailingAddress.country"
    }
  }) : _vm._e()] : _vm._e(), _vm.viewType === 'editInvestor' ? _c('div', {
    staticClass: "add-new-investor-form__propagate mb-4"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary propagate",
    attrs: {
      "value": _vm.propagate,
      "removeWatcher": true,
      "label": "PROPAGATE THE DATA"
    },
    on: {
      "changedValue": _vm.setPropagate
    }
  }), _c('p', {
    class: ['add-new-investor-form__note', !_vm.propagate ? 'hidden' : '']
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Important!")]), _vm._v(" Remember that this action will result in changes across all Legal Entities")])], 1) : _vm._e(), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary button secondary mb-4 mt-2",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(" Save ")]), _c('router-link', {
    attrs: {
      "to": _vm.capitalRiseLink
    }
  }, [_c('div', {
    staticClass: "cancel-button"
  }, [_vm._v("Cancel")])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }